import ComingSoon from "./Components/ComingSoon";
import "./App.css";

function App() {
  return (
    <>
      <ComingSoon />
    </>
  );
}

export default App;
